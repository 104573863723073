export const imports = {
  'index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "index" */ 'index.mdx'),
  'book/fragment_shader.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "book-fragment-shader" */ 'book/fragment_shader.mdx'
    ),
  'book/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "book-index" */ 'book/index.mdx'
    ),
  'overview/advance.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "overview-advance" */ 'overview/advance.mdx'
    ),
  'overview/getting_start.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "overview-getting-start" */ 'overview/getting_start.mdx'
    ),
  'overview/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "overview-index" */ 'overview/index.mdx'
    ),
  'overview/modules.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "overview-modules" */ 'overview/modules.mdx'
    ),
  'overview/uniforms.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "overview-uniforms" */ 'overview/uniforms.mdx'
    ),
}
